<template>
  <b-sidebar
    v-if="isSidebarActive"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Category Detail
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>

      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="CategoriesDetailForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="categories.level"
            disabled
            name="level"
            label="Level"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            disabled
            name="Parent Name"
            label="Parent Name"
            :value="findNameParent"
            trim />
          <TextFieldInput
            v-model="categories.name"
            name="Name"
            label="Name"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="categories.resolvedDay"
            name="Resolve Day"
            label="Resolved Day"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="categories.resolvedHour"
            name="Resolved Hour"
            label="Resolved Hour"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="categories.resolvedMin"
            name="Resolved Min"
            label="Resolved Min"
            :rules="{ required: true }"
            trim />
          <b-row class="align-items-end">
            <b-col>
              <SelectField
                v-model="categories.priority"
                :options="categoriesEnum"
                name="Priority"
                form-label="Priority"
                label-option="label"
                :rules="{
                  required: true,
                }"
                return-value="value" />
            </b-col>
          </b-row>
          <b-row class="align-items-end">
            <b-col>
              <SelectField
                v-model="categories.urgency"
                :options="categoriesEnum"
                name="Urgency"
                form-label="Urgency"
                label-option="label"
                :rules="{
                  required: true,
                }"
                return-value="value" />
            </b-col>
          </b-row>
          <b-row class="align-items-end">
            <b-col>
              <SelectField
                v-model="categories.impact"
                :options="categoriesEnum"
                :rules="{
                  required: true,
                }"
                name="Impact"
                form-label="Impact"
                label-option="label"
                return-value="value" />
            </b-col>
          </b-row>
          <StatusRadioButton :status.sync="categories.status" />
          <b-row>
            <b-col>
              Created At : {{ categories.createdAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Created By : {{ categories.createdBy.username }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated At : {{ categories.updatedAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated By : {{ categories.updatedBy.username }}
            </b-col>
          </b-row>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit"
              :disabled="compareOldValue">
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              :disabled="compareOldValue"
              @click="resetForm()">
              Cancel
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon ml-auto"
              @click="deleteItem()">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import SelectField from '@/components/Form/SelectField.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import StatusRadioButton from '@/components/Form/StatusRadioButton.vue'

export default {
  components: { TextFieldInput, StatusRadioButton, SelectField },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    initialCategories: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    },
    allCategories: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      categoriesEnum: [
        { label: 'low', value: 'low' },
        { label: 'medium', value: 'medium' },
        { label: 'high', value: 'high' }
      ]
    }
  },
  computed: {
    categories: {
      get () {
        return this.selectedItem
      },
      set (val) {
        this.$emit('update:selected-item', val)
      }
    },
    compareOldValue () {
      return JSON.stringify(this.categories) === JSON.stringify(this.initialCategories)
    },
    findNameParent () {
      const filter = this.allCategories.filter((item) => item.id === this.categories.parentId)
      if (filter && filter.length > 0) {
        return filter[0].name
      }
      return '-'
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      const payload = {
        id: this.categories.id,
        name: this.categories.name,
        resolvedDay: this.categories.resolvedDay,
        resolvedHour: this.categories.resolvedHour,
        resolvedMin: this.categories.resolvedMin,
        priority: this.categories.priority,
        urgency: this.categories.urgency,
        impact: this.categories.impact,
        status: this.categories.status
      }
      this.$emit('update-categories', payload)
    },
    resetForm () {
      this.categories = { ...this.initialCategories }
      this.$refs.CategoriesDetailForm.reset()
    },
    deleteItem () {
      this.$emit('delete-item', {
        ID: this.categories.id,
        Name: this.categories.name
      })
    }
  }
}
</script>

<style>

</style>
