<template>
  <div>
    <CategoriesFilter
      :filter.sync="filter"
      :search.sync="filter.searchName"
      :status-filter.sync="filter.status"
      :search-priority.sync="filter.searchPriority"
      :search-uregency.sync="filter.searchUregency"
      :search-impact.sync="filter.searchImpact"
      @fetch-filter="fetchFilter()" />
    <CategoriesDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-categories="initialCategories"
      :selected-item.sync="selectedItem"
      :all-categories="categories"
      @update-categories="updateCategories($event)"
      @delete-item="openDeleteModal($event)" />
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Categories"
        to-path="/category/add"
        @change="fetchFilter()" />
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        @sort-changed="sortFilter($event)"
        @row-clicked="rowClick($event)">
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
        <template #cell(sla)="data">
          <span class="font-weight-semibold">
            {{ data.item.resolvedDay }} D
            {{ data.item.resolvedHour }} H
            {{ data.item.resolvedMin }} M
          </span>
        </template>
        <template #cell(parent)="data">
          <span class="font-weight-semibold">
            {{ findNameParent(data.item.parentId) }}
          </span>
        </template>
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
        <template #cell(name)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(priority)="data">
          <b-badge :variant="$categoryValue(data.value)">
            {{ data.value | formatCategoryData }}
          </b-badge>
        </template>
        <template #cell(urgency)="data">
          <b-badge :variant="$categoryValue(data.value)">
            {{ data.value | formatCategoryData }}
          </b-badge>
        </template>
        <template #cell(impact)="data">
          <b-badge :variant="$categoryValue(data.value)">
            {{ data.value | formatCategoryData }}
          </b-badge>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CategoriesFilter from '@/components/Categories/CategoriesFilter.vue'
import TableTop from '@/components/TableTop.vue'
import CategoriesProvider from '@/resources/CategoriesProvider'
import CategoriesDetail from '@/components/Categories/CategoriesDetail.vue'
import DeleteModal from '@/components/DeleteModal.vue'

const CategoriesService = new CategoriesProvider()

export default {
  components: {
    TableTop,
    CategoriesFilter,
    CategoriesDetail,
    DeleteModal
  },
  data () {
    return {
      statusFilter: null,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        searchName: '',
        searchPriority: '',
        searchUregency: '',
        searchImpact: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'name', label: 'Category Name' },
        { key: 'level', label: 'Level' },
        { key: 'parent', label: 'Parent' },
        { key: 'sla', label: 'SLA' },
        { key: 'priority', label: 'Priority' },
        { key: 'urgency', label: 'Urgency' },
        { key: 'impact', label: 'Impact' },
        { key: 'status', label: 'Status' }
      ],
      items: [
        {
          code: '01',
          name: 'test01',
          parentId: '01',
          sla: '20',
          status: 'active'
        },
        {
          code: '02',
          name: 'test02',
          parentId: '02',
          sla: '20',
          status: 'active'
        }
      ],
      selectedItem: {},
      initialCategories: {},
      deleteItem: {},
      categories: []
    }
  },
  async mounted () {
    await this.getCategories()
    await this.getAllCategories()
    await this.getGroupService()
  },
  methods: {
    async getCategories () {
      try {
        const data = await CategoriesService.paginate(this.pagination.page, this.pagination.limit, this.filter)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async getAllCategories () {
      try {
        const { data } = await CategoriesService.findAll()
        this.categories = [...data]
      } catch (err) {
        console.error(err)
      }
    },
    async updateCategories (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await CategoriesService.updateGroup(val.id, payload)
        this.setInitialDataAndSelectItem(data)
        await this.getCategories()
        await this.getAllCategories()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Categories has been updated'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Update Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Categories has been Failed${ err}`
          }
        })
      }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await CategoriesService.deleteGroup(val.ID)
        await this.getCategories()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: `👋 Categories ${this.deleteItem.Name} has been deleted`
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `👋 Categories ${this.deleteItem.Name} has been Failed`
          }
        })
      }
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    rowClick (val) {
      const payload = { ...val }
      payload.parentName = this.findNameParent(val.parentId)
      this.setInitialDataAndSelectItem(payload)
      this.isSidebarDetailActive = true
    },
    setInitialDataAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialCategories = { ...val }
    },
    fetchFilter () {
      this.setPagination(1)
      this.getCategories()
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getCategories()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    },
    findNameParent (parentId) {
      const filter = this.categories.filter((item) => item.id === parentId)
      if (filter && filter.length > 0) {
        return filter[0].name
      }
      return '-'
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
