<template>
  <b-row class="align-items-end">
    <b-col
      class="mb-1 mb-sm-0"
      sm="12"
      md="3">
      <SearchTextInput
        v-model="searchValue"
        label="Search"
        placeholder="Category Name"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      class="mb-1 mb-sm-0"
      sm="12"
      md="3">
      <b-form-group
        class="font-weight-semibold mb-0"
        label="Priority">
        <v-select
          v-model="searchPriority"
          :options="categoryItems"
          name="Priority"
          label="label"
          :reduce="val => val['value']"
          placeholder="Status"
          @input="fetchFilter()"
        />
      </b-form-group>
    </b-col>
    <b-col
      class="mb-1 mb-sm-0"
      sm="12"
      md="3">
      <b-form-group
        class="font-weight-semibold mb-0"
        label="Uregency">
        <v-select
          v-model="searchUregency"
          :options="categoryItems"
          name="Priority"
          label="label"
          :reduce="val => val['value']"
          placeholder="Status"
          @input="fetchFilter()"
        />
      </b-form-group>
    </b-col>
    <b-col
      class="mb-1 mb-sm-0"
      sm="12"
      md="3">
      <b-form-group
        class="font-weight-semibold mb-0"
        label="Impact">
        <v-select
          v-model="searchImpact"
          :options="categoryItems"
          name="Priority"
          label="label"
          :reduce="val => val['value']"
          placeholder="Status"
          @input="fetchFilter()"
        />
      </b-form-group>
    </b-col>
    <b-col
      class="mb-1 mb-sm-0"
      sm="12"
      md="5">
      <StatusDropdown
        :status-filter.sync="statusFilterValue"
        @change-status-filter="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="w-100"
        variant="primary"
        @click="fetchFilter()">
        <feather-icon
          icon="SearchIcon"
          class="mr-50 mr-md-0 mr-lg-0 mr-xl-50" />
        <span class="align-middle">Search</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import StatusDropdown from '@/components/Filter/StatusDropdown.vue'

export default {
  components: {
    StatusDropdown,
    SearchTextInput
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    statusFilter: {
      type: String,
      default: null
    },
    filter: {
      type: Object,
      default: () => (
        {
          searchPriority: '',
          searchUregency: '',
          searchImpact: ''
        }
      )
    }
  },
  data () {
    return {
      categoryItems: [
        { label: 'low', value: 'low' },
        { label: 'medium', value: 'medium' },
        { label: 'high', value: 'high' }
      ]
    }
  },
  computed: {
    searchValue: {
      get () {
        return this.search
      },
      set (val) {
        this.$emit('update:search', val)
      }
    },
    searchPriority: {
      get () {
        return this.filter.searchPriority
      },
      set (val) {
        this.$emit('update:search-priority', val)
      }
    },
    searchUregency: {
      get () {
        return this.filter.searchUregency
      },
      set (val) {
        this.$emit('update:search-uregency', val)
      }
    },
    searchImpact: {
      get () {
        return this.filter.searchImpact
      },
      set (val) {
        this.$emit('update:search-impact', val)
      }
    },
    statusFilterValue: {
      get () {
        return this.statusFilter
      },
      set (val) {
        this.$emit('update:status-filter', val)
      }
    }
  },
  methods: {
    fetchFilter () {
      this.$emit('fetch-filter')
    }
  }
}
</script>

<style>

</style>
